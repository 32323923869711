:root {
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font-family-fixed: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  --max-width: 80rem;
  --chart-height: 180px;
}

html {
  box-sizing: border-box;
}

body {
  color: var(--color-text);
}

h1, h2, h3, h4, h5 {
  margin-bottom: var(--space-small);
  letter-spacing: -0.005em;
  color: var(--color-heading);
}

h1, h2, h3 {
  font-weight: 500;
}

code {
  font-family: var(--font-family-fixed);
}

/** Orbit updates */
a {
  color: var(--color-primary);
}

a:hover,
a:active {
  color: var(--color-primary-dark);
}

._2atV0 {
  background: var(--color-primary);
}

._2atV0:active,
._2atV0:focus,
._2atV0:hover {
  background: var(--color-primary-dark);
}

._3-NNC {
  background: var(--color-secondary);
}

._3-NNC:active,
._3-NNC:focus,
._3-NNC:hover {
  background: var(--color-secondary-dark);
}

._3bG7i {
  background: var(--color-info);
}

._3bG7i:active,
._3bG7i:focus,
._3bG7i:hover {
  background: var(--color-info-dark);
}

.bnAnQ {
  background: var(--color-success);
}

.bnAnQ:active,
.bnAnQ:focus,
.bnAnQ:hover {
  background: var(--color-success-dark);
}


._2j2Lh {
  background: var(--color-warning);
}

._2j2Lh:active,
._2j2Lh:focus,
._2j2Lh:hover {
  background: var(--color-warning-dark);
}

._1NejK {
  background: var(--color-danger);
}

._1NejK:active,
._1NejK:focus,
._1NejK:hover {
  background: var(--color-danger-dark);
}

@media (min-width: 600px) {
  html {
    font-size: 81.25%;
  }
}
